import axios from "axios";
import { Eye } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import "../../Assests/css/aplicationform.css";
import {
  handleAlphabetsNumberWithoutSpaceChange,
  handleError
} from "../../CommonJquery/CommonJquery";
import { retrieveData, storeData } from "../../LocalConnection/LocalConnection";
import {
  APL_LINK,
  get_booking_data_main,
  save_booking_Aggrement,
  save_image_pdf,
  server_post_data,
} from "../../ServiceConnection/serviceconnection";
import Layout from "../Layout/Layout";
const Agreement = () => {
  // Add this array at the top of your component, after the useState declarations
  const usStates = [
    { name: "Alabama", abbr: "AL" },
    { name: "Alaska", abbr: "AK" },
    { name: "Arizona", abbr: "AZ" },
    { name: "Arkansas", abbr: "AR" },
    { name: "California", abbr: "CA" },
    { name: "Colorado", abbr: "CO" },
    { name: "Connecticut", abbr: "CT" },
    { name: "Delaware", abbr: "DE" },
    { name: "Florida", abbr: "FL" },
    { name: "Georgia", abbr: "GA" },
    { name: "Hawaii", abbr: "HI" },
    { name: "Idaho", abbr: "ID" },
    { name: "Illinois", abbr: "IL" },
    { name: "Indiana", abbr: "IN" },
    { name: "Iowa", abbr: "IA" },
    { name: "Kansas", abbr: "KS" },
    { name: "Kentucky", abbr: "KY" },
    { name: "Louisiana", abbr: "LA" },
    { name: "Maine", abbr: "ME" },
    { name: "Maryland", abbr: "MD" },
    { name: "Massachusetts", abbr: "MA" },
    { name: "Michigan", abbr: "MI" },
    { name: "Minnesota", abbr: "MN" },
    { name: "Mississippi", abbr: "MS" },
    { name: "Missouri", abbr: "MO" },
    { name: "Montana", abbr: "MT" },
    { name: "Nebraska", abbr: "NE" },
    { name: "Nevada", abbr: "NV" },
    { name: "New Hampshire", abbr: "NH" },
    { name: "New Jersey", abbr: "NJ" },
    { name: "New Mexico", abbr: "NM" },
    { name: "New York", abbr: "NY" },
    { name: "North Carolina", abbr: "NC" },
    { name: "North Dakota", abbr: "ND" },
    { name: "Ohio", abbr: "OH" },
    { name: "Oklahoma", abbr: "OK" },
    { name: "Oregon", abbr: "OR" },
    { name: "Pennsylvania", abbr: "PA" },
    { name: "Rhode Island", abbr: "RI" },
    { name: "South Carolina", abbr: "SC" },
    { name: "South Dakota", abbr: "SD" },
    { name: "Tennessee", abbr: "TN" },
    { name: "Texas", abbr: "TX" },
    { name: "Utah", abbr: "UT" },
    { name: "Vermont", abbr: "VT" },
    { name: "Virginia", abbr: "VA" },
    { name: "Washington", abbr: "WA" },
    { name: "West Virginia", abbr: "WV" },
    { name: "Wisconsin", abbr: "WI" },
    { name: "Wyoming", abbr: "WY" },
    { name: "District of Columbia", abbr: "DC" },
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingId, carId } = location.state;
  console.log(bookingId, carId);
  const [step, setStep] = useState(1);
  const maxDob = new Date();
  const [agreed, setAgreed] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(null);
  const [rideshare, setrideshare] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [licenseImage, setLicenseImage] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileData, setFileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isPdfFile, setIsPdfFile] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  maxDob.setFullYear(maxDob.getFullYear() - 16);
  const customer_id = retrieveData("customer_id");
  const customer_name = retrieveData("customer_name");
  const customer_email = retrieveData("customer_email");
  const [showPopup, setShowPopup] = useState(false);
  const [termConditionModal, setTermConditionModal] = useState(false);
  const [drivingState, setdrivingState] = useState(true);
  const dob_date = retrieveData("dob_date");
  const driving_expiration_date = retrieveData("driving_expiration_date");
  const emergency_contacts = retrieveData("emergency_contacts");
  const emergency_contacts1 = retrieveData("emergency_contacts1");
  const emergency_contacts_name = retrieveData("emergency_contacts_name");
  const emergency_contacts_name1 = retrieveData("emergency_contacts_name1");
  const emergency_contacts_relation = retrieveData(
    "emergency_contacts_relation"
  );
  const emergency_contacts_relation1 = retrieveData(
    "emergency_contacts_relation1"
  );
  const present_map_address = retrieveData("present_map_address");

  const nameParts = customer_name.trim().split(" ");
  const first_name = nameParts.length > 0 ? nameParts[0] : "";
  const last_name = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const [formData, setFormData] = useState({
    mvrDownloaded: "yes",
    mvrFile: null,
    driving_licence_image: null,
    feeAgreement: "",
    firstName: first_name,
    lastName: last_name,
    dob: "",
    drivingState: "",
    licenseState: "",
    licenseExpiry:'',
    phone: "",
    address: present_map_address,
    email: customer_email,
    ssn: "",
    license: "",
    // rideshareApproval: "no",
    hireExperience: "",
    carPreference: [],
    rentalDuration: "",
    rentalStart: "",
    carUsage: [],
    carUsageOther: "",
    workApproval: [],
    workApprovalOther: "",
    emergencyContact1: {
      name: emergency_contacts_name,
      relation: emergency_contacts_relation,
      phone: emergency_contacts,
    },
    emergencyContact2: {
      name: emergency_contacts_name1,
      relation: emergency_contacts_relation1,
      phone: emergency_contacts1,
    },
    city: "",
    zipcode: "",
    submissionDate: "",
    signature_image: null,
  });

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "phone" || name === "zipcode") {
      // Remove non-digit characters and limit to 10 digits
      const formattedPhone = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: formattedPhone });
    } else if (type === "checkbox") {
      console.log(name, value, type, checked);
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevData[name], value]
          : prevData[name].filter((item) => item !== value),
      }));
    } else if (type === "file") {
      let data = await handlesaveImage(e.target.files[0]);
      setFormData({ ...formData, [name]: data });
      // setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handlesaveImage = async (file) => {
    if (!file) return "";

    let data = "";
    let fd_from = new FormData();
    fd_from.append("image", file);

    try {
      const response = await server_post_data(save_image_pdf, fd_from);
      if (response.error) {
        handleError(response.data.message);
      } else {
        data = response.message.image;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    return data;
  };

  // const handleEmergencyChange = (e, contactNumber) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [`emergencyContact${contactNumber}`]: {
  //       ...prevData[`emergencyContact${contactNumber}`],
  //       [name]: value,
  //     },
  //   }));
  // };

  //   useEffect(() => {
  //     if (!data?.endDate || !data?.fromDate) {
  //       console.error("Dates are missing.");
  //       return;
  //     }

  // const endDate = new Date(data.endDate);
  // const fromDate = new Date(data.fromDate);

  // if (isNaN(endDate) || isNaN(fromDate)) {
  //   console.error("Invalid date format.");
  //   return;
  // }

  //     const differenceInTime = endDate - fromDate;
  //     let daysDifference = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  //     if (daysDifference === 0) {
  //       daysDifference = 1;
  //     }

  //     if (daysDifference > 0) {
  //       setNumberOfDays(daysDifference);
  //       const carPrice = Number(data.car_price);
  //       const total = daysDifference * carPrice;
  //       setTotalCost(total);
  //     } else {
  //       console.error("End date is earlier than the from date.");
  //     }
  //   }, [data.endDate, data.fromDate]);

  // const handleNext = () => setStep(step + 1);

  console.log("mod Data 2", formData.workApproval);

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    const fd = new FormData();
    fd.append("booking_id", bookingId);
    await server_post_data(get_booking_data_main, fd)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.message);
        } else {
          // console.log("gdd", Response.message.image_link)
          // console.log("gddasasas", Response.message.data.licenseState)
          let finaldata = Response.message.data;
          console.log("dsdsd", Response);
          // console.log("finaldata", finaldata.using_car_for)
          let workApprovalData = [];
          let workApprovalOtherData = "";

          // if (finaldata.work_for) {
          //   workApprovalData = finaldata.work_for.split(",").map((item) => {
          //     if (item.includes("~@~")) {
          //       const [_, otherValue] = item.split("~@~");
          //       workApprovalOtherData = otherValue; // Other ka value yahan save hoga
          //       return "Other";
          //     }
          //     return item;
          //   });
          // }

          let usingCarForData = [];
          let usingCarForOtherData = "";

          // if (finaldata.using_car_for) {
          //   usingCarForData = finaldata.using_car_for.split(",").map((item) => {
          //     if (item.includes("~@~")) {
          //       const [_, otherValue] = item.split("~@~");
          //       usingCarForOtherData = otherValue;
          //       return "Other";
          //     }
          //     return item;
          //   });
          // }

          console.log("hello please", finaldata);

          const licenseImageUrl =
            APL_LINK +
            Response.message.image_link +
            finaldata.driving_licence_image || "";
          setLicenseImage(licenseImageUrl);
          console.log("License URL:", licenseImageUrl);

          if (licenseImageUrl) {
            const fileExtension = licenseImageUrl
              .split(".")
              .pop()
              .toLowerCase();
            console.log("File extension:", fileExtension);

            if (["pdf"].includes(fileExtension)) {
              console.log("File type: PDF");
              setFileType("pdf");
            } else if (
              ["jpg", "jpeg", "png", "gif", "webp"].includes(fileExtension)
            ) {
              console.log("File type: Image");
              setFileType("image");
            } else {
              console.log("Unknown file type, extension:", fileExtension);
              setFileType("unknown");
            }
          }
          setrideshare(finaldata.ready_for_rideshare);
          setFormData({
            mvrDownloaded: "yes",
            mvrFile: null,
            driving_licence_image: finaldata.driving_licence_image || "",
            feeAgreement: "",
            firstName: finaldata.first_name,
            lastName: finaldata.last_name,
            dob: "",
            drivingState: finaldata.state,
            licenseState: "",
            licenseExpiry: "",
            phone: finaldata.mobile_no,
            address: finaldata.present_map_address,
            email: finaldata.email,
            ssn: finaldata.last_digit_ssn,
            license: finaldata.driving_licence,
            // rideshareApproval: "no",
            hireExperience: finaldata.passenger_for_hire_expe,
            carPreference: [],
            rentalDuration: finaldata.long_need_car,
            rentalStart: "",
            carUsage: finaldata.using_car_for
              ? finaldata.using_car_for
                .split(",")
                .map((item) => item.trim())
                .filter(Boolean)
              : [],
            carUsageOther: usingCarForOtherData,
            workApproval: finaldata.work_for
              ? finaldata.work_for
                .split(",")
                .map((item) => item.trim())
                .filter(Boolean)
              : [],
            workApprovalOther: workApprovalOtherData,
            emergencyContact1: {
              name: "",
              relation: "",
              phone: "",
            },
            emergencyContact2: {
              name: "",
              relation: "",
              phone: "",
            },
            city: finaldata.city,
            zipcode: finaldata.pin_code,
            submissionDate: "",
            signature_image: null,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchFileForPreview = async (url) => {
    setIsLoading(true);
    setLoadError(false);

    try {
      console.log("Fetching file from:", url);

      // Determine file type from URL extension
      const extension = url.split(".").pop().toLowerCase();
      const isPdf = extension === "pdf";
      setFileType(isPdf ? "pdf" : "image");

      // Use axios to fetch the file as a blob
      const response = await axios.get(url, {
        responseType: "blob",
        withCredentials: true, // Include cookies if needed for auth
      });

      console.log("File fetched successfully:", response);

      // Create a local object URL from the blob
      const objectUrl = URL.createObjectURL(response.data);
      setFileData(objectUrl);
      setLoadError(false);
    } catch (error) {
      console.error("Error fetching file:", error);
      setLoadError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => setStep(step - 1);
  const [errors, setErrors] = useState({});

  const step1Errors = () => {
    let errors = {};

    if (!formData.firstName) errors.firstName = "First name is required.";
    if (!formData.lastName) errors.lastName = "Last name is required.";
    if (!formData.dob) errors.dob = "Date of Birth is required.";
    if (!formData.licenseExpiry)
      errors.licenseExpiry = "License Expiry date is required.";
    if (!formData.phone) {
      errors.phone = "Phone number is required.";
    } else {
      // Remove any non-digit characters
      const phoneNumber = formData.phone.replace(/\D/g, "");

      // Check if the phone number is exactly 10 digits
      if (phoneNumber.length !== 10) {
        errors.phone = "Phone number must be 10 digits long.";
      } else {
        // Additional validation for common US phone number formats
        const firstThreeDigits = phoneNumber.substring(0, 3);
        const invalidAreaCodes = ["000", "111", "911"];

        if (invalidAreaCodes.includes(firstThreeDigits)) {
          errors.phone = "Invalid phone number format.";
        }
      }
    }
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.address) errors.address = "Address is required.";
    if (!formData.city) errors.city = "City is required.";
    if (!formData.zipcode) errors.zipcode = "Zipcode is required.";
    // if (!formData.driving_licence_image)
    //   errors.driving_licence_image = "Please upload a PDF of your license.";
    if (!formData.drivingState) errors.drivingState = "Please select a state.";
    return errors;
  };
  const validate = () => {
    let newErrors = {};
    if (!formData.ssn) newErrors.ssn = "SSN is required.";
    if (!formData.license) newErrors.license = "Driver's license is required.";
    // if (!formData.driving_licence_image)
    //   newErrors.driving_licence_image = "Please upload a PDF of your license.";
    // if (!formData.rideshareApproval)
    //   newErrors.rideshareApproval = "This field is required.";
    // if (formData.rideshareApproval === "yes" && !formData.hireExperience) {
    //   newErrors.hireExperience = "Experience is required.";
    // }
    if (!formData.licenseState)
      newErrors.licenseState = "Please select a state.";
    if (rideshare === "no") {
      if (formData.carUsage.length === 0)
        newErrors.carUsage = "Please select at least one usage.";
      if (formData.carUsage.includes("Other") && !formData.carUsageOther)
        newErrors.carUsageOther = "Specify other car usage.";
    }
    else {
      if (formData.workApproval.length === 0)
        newErrors.workApproval = "Please select at least one approval.";
      if (formData.workApproval.includes("Other") && !formData.workApprovalOther)
        newErrors.workApprovalOther = "Specify other platform.";
    }


    // if (!formData.rentalStart)
    //   newErrors.rentalStart = "Please select a rental start option.";
    // Validate Emergency Contact 1
    if (!formData.emergencyContact1.name)
      newErrors.emergencyContact1_name = "Contact 1 Name is required.";
    if (!formData.emergencyContact1.relation)
      newErrors.emergencyContact1_relation = "Contact 1 Relation is required.";
    if (!formData.emergencyContact1.phone)
      newErrors.emergencyContact1_phone = "Contact 1 Phone is required.";

    // Validate Emergency Contact 2
    if (!formData.emergencyContact2.name)
      newErrors.emergencyContact2_name = "Contact 2 Name is required.";
    if (!formData.emergencyContact2.relation)
      newErrors.emergencyContact2_relation = "Contact 2 Relation is required.";
    if (!formData.emergencyContact2.phone)
      newErrors.emergencyContact2_phone = "Contact 2 Phone is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    const errors = step1Errors();
    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Assume `errors` is a state variable to store error messages
      return;
    }
    setStep(step + 1);
  };
  const handleCheckboxChange = (e) => {
    setAgreed(e.target.checked);
  };

  const signaturePad = useRef(null);

  const handleClearSignature = () => {
    signaturePad.current.clear();
  };

  const handleSaveSignature = () => {
    console.log("Hello I am Here");
    if (!signaturePad.current || signaturePad.current.isEmpty()) {
      alert("Please provide a signature before proceeding.");
      return;
    }

    const signatureData = signaturePad.current.toDataURL("image/png");
    console.log("Signature Data URL:", signatureData); // Logs the Base64 data URL

    const base64String = signatureData.split(",")[1];
    const byteArray = new Uint8Array(
      atob(base64String)
        .split("")
        .map((c) => c.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: "image/png" });
    const file = new File([blob], "signature.png", { type: "image/png" });

    // setSignatureFile(file);
    console.log("Signature File:", file); // Logs the File object
    setFormData((prevData) => ({
      ...prevData,
      signature_image: file,
    }));

    alert("Signature saved as PNG and downloaded!");
  };

  const handleSubmitImg = async () => {
    let fd_from = new FormData();
    fd_from.append("signature_image", formData.signature_image);
    fd_from.append(
      "emergency_contacts_relation1",
      formData.emergencyContact2.relation
    );
    fd_from.append("emergency_contacts_name1", formData.emergencyContact2.name);
    fd_from.append("emergency_contacts1", formData.emergencyContact2.phone);
    fd_from.append(
      "emergency_contacts_relation",
      formData.emergencyContact1.relation
    );
    fd_from.append("emergency_contacts_name", formData.emergencyContact1.name);
    fd_from.append("emergency_contacts", formData.emergencyContact1.phone);

    {
      rideshare === "no" && (

        fd_from.append("using_car", formData.carUsage)
      )
      // : fd_from.append("work_for", "");
    }
    {
      rideshare === "yes" && (

        fd_from.append("work_for", formData.workApproval)
      )
      // : fd_from.append("using_car", "")
    }

    fd_from.append("long_need_car", formData.rentalDuration);
    fd_from.append("passenger_for_hire_expe", "");
    fd_from.append("ready_for_rideshare", "");
    fd_from.append("driving_licence", formData.license);
    fd_from.append("last_digit_ssn", formData.ssn);
    fd_from.append("present_map_address", formData.address);
    fd_from.append(
      "driving_expiration_date",
      formatDateTimenew(formData.licenseExpiry)
    );
    fd_from.append("mvr_pdf", formData.mvrFile);
    fd_from.append("mvr_status", formData.mvrDownloaded);
    fd_from.append("driving_licence_image", "");
    fd_from.append("end_date", "1");
    fd_from.append("from_date", "2025-03-26");
    fd_from.append("car_id", carId);
    fd_from.append("booking_id", bookingId);
    fd_from.append("car_price", "23");
    fd_from.append("total_price", totalCost);
    fd_from.append("mobile_no", formData.phone);
    fd_from.append("email", formData.email);
    fd_from.append("dob", formatDateTimenew(formData.dob));
    fd_from.append("last_name", formData.lastName);
    fd_from.append("first_name", formData.firstName);
    fd_from.append("state_dropdown", formData.licenseState);
    fd_from.append("driving_state", formData.drivingState);
    fd_from.append("pin_code", formData.zipcode);
    await server_post_data(save_booking_Aggrement, fd_from)
      .then((Response) => {
        if (Response.error) {
          handleError(Response.data.message);
        } else {
          storeData("dob_date", formData.dob);
          storeData("driving_expiration_date", formData.licenseExpiry);
          storeData("emergency_contacts", formData.emergencyContact1.phone);
          storeData("emergency_contacts1", formData.emergencyContact2.phone);
          storeData("emergency_contacts_name", formData.emergencyContact1.name);
          storeData(
            "emergency_contacts_name1",
            formData.emergencyContact2.name
          );
          storeData(
            "emergency_contacts_relation",
            formData.emergencyContact1.relation
          );
          storeData(
            "emergency_contacts_relation1",
            formData.emergencyContact2.relation
          );
          storeData("present_map_address", formData.address);
          storeData(
            "customer_name",
            formData.firstName + " " + formData.lastName
          );

          navigate("/my_bookings");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(formData.mvrDownloaded)
  // console.log(formData.carUsage.join(','));
  function formatDateTime(date) {
    const d = new Date(date);

    // Get date components
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");

    // Get time components
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");

    // Combine into desired format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function formatDateTimenew(date) {
    const d = new Date(date);

    // Get date components
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");

    // Combine into desired format
    return `${year}-${month}-${day}`;
  }

  const [error, setError] = useState("");

  // Handle changes in the emergency contact fields
  const handleEmergencyChange = (e, contactNumber) => {
    const { name, value } = e.target;

    // Update the form data
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[`emergencyContact${contactNumber}`][name] = value;

      // Get both emergency contact details
      const contact1 = updatedData.emergencyContact1;
      const contact2 = updatedData.emergencyContact2;

      // Check if any field of contact 1 matches the corresponding field of contact 2
      if (
        contact1.name === contact2.name ||
        contact1.relation === contact2.relation ||
        contact1.phone === contact2.phone
      ) {
        setError(
          "Emergency Contact 1 and Emergency Contact 2 cannot have the same name, relation, or phone number."
        );
      } else {
        setError(""); // Clear the error if they are not the same
      }

      return updatedData;
    });
  };
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const termsRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setTermConditionModal(true);
    }
  };

  const confirmSubmit = async () => {
    setTermConditionModal(false);
    await handleSubmitImg();
  };
  useEffect(() => {
    if (termConditionModal) {
      const termsDiv = document.getElementById("terms_Contrrr");
      if (termsDiv) {
        const handleScroll = () => {
          const { scrollTop, scrollHeight, clientHeight } = termsDiv;

          // Check if the user has scrolled to the bottom
          if (scrollTop + clientHeight >= scrollHeight - 5) {
            console.log("Scrolled to bottom - checkbox enabled");
            setIsCheckboxEnabled(true); // Enable the checkbox
          } else {
            console.log("Not scrolled to bottom - checkbox disabled");
            setIsCheckboxEnabled(false); // Disable the checkbox
          }
        };

        termsDiv.addEventListener("scroll", handleScroll);

        // Cleanup the event listener on modal close
        return () => {
          termsDiv.removeEventListener("scroll", handleScroll);
        };
      }
    }
  }, [termConditionModal]);

  console.log("formData", formData.workApproval);

  useEffect(() => {
    if (licenseImage) {
      // Check if the license image URL ends with .pdf
      const isPdf = licenseImage.toLowerCase().endsWith(".pdf");
      setIsPdfFile(isPdf);
      console.log("License file is PDF:", isPdf);
    }
  }, [licenseImage]);

  return (
    <Layout>
      <div>
        <div className="page-header pt-30 background-body">
          <div className="position-relative mx-auto">
            <div className="container">
              <h5 className="text-dark">Agreement Form</h5>
              {/* <span className="text-dark text-xl-medium">
                Start your car agreement booking by completing this quick 2-step
                application!
              </span> */}
            </div>
          </div>
          <div className="container">
            <div className="mt-4 mb-4 form-container">
              <form onSubmit={handleSubmit}>
                {step === 1 && (
                  <div className="form-step">
                    <h6 className="mb-4">Step 1: Basic Information</h6>
                    <div className="row m-0">
                      <label key="firstName" className="col-md-6 mb-3">
                        <span>
                          First Name <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="text"
                          name="firstName"
                          value={formData.firstName}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              firstName: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only alphabets
                            }
                          }}
                          maxLength={12}
                          required
                        />
                        {errors.firstName && (
                          <span className="error-message">
                            {errors.firstName}
                          </span>
                        )}
                      </label>

                      <label key="lastName" className="col-md-6 mb-3">
                        <span>
                          Last Name <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="text"
                          name="lastName"
                          value={formData.lastName}
                          maxLength={12}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              lastName: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[a-zA-Z]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only alphabets
                            }
                          }}
                          required
                        />
                        {errors.lastName && (
                          <span className="error-message">
                            {errors.lastName}
                          </span>
                        )}
                      </label>

                      <label key="phone" className="col-md-6 mb-3">
                        <span>
                          Phone Number <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="tel"
                          name="phone"
                          maxLength={10}
                          value={formData.phone}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              phone: "",
                            }));
                          }}
                          onKeyPress={(e) => {
                            if (!/^[0-9]*$/.test(e.key)) {
                              e.preventDefault(); // Allow only numbers
                            }
                          }}
                          required
                        />
                        {errors.phone && (
                          <span className="error-message">{errors.phone}</span>
                        )}
                      </label>

                      <label key="email" className="col-md-6 mb-3">
                        <span>
                          Email <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="email"
                          name="email"
                          maxLength={50}
                          value={formData.email}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              email: "",
                            }));
                          }}
                          required
                          onKeyPress={(e) => {
                            const allowedChars = /^[a-zA-Z0-9@.]*$/;
                            if (!allowedChars.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            const emailPattern =
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            if (!emailPattern.test(e.target.value)) {
                              setErrors({
                                ...errors,
                                email: "Please enter a valid email address",
                              });
                            }
                          }}
                        />
                        {errors.email && (
                          <span className="error-message">{errors.email}</span>
                        )}
                      </label>

                      <label className="col-md-6 mb-3">
                        <span>
                          Date of Birth <span style={{ color: "red" }}>*</span>
                        </span>
                        <DatePicker
                          selected={formData.dob}
                          onChange={(date) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              dob: date,
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              dob: "",
                            }));
                          }}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="search-input datepicker"
                          maxDate={maxDob}
                        />
                        {errors.dob && (
                          <span className="error-message">{errors.dob}</span>
                        )}
                      </label>

                      {/* License Expiry */}
                      <label className="col-md-6 mb-3">
                        <span>
                          Expiration of Driving License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <DatePicker
                          selected={formData.licenseExpiry}
                          onChange={(date) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              licenseExpiry: date,
                            }));
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              licenseExpiry: "",
                            }));
                          }}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="search-input datepicker"
                          minDate={new Date()}
                        />
                        {errors.licenseExpiry && (
                          <span className="error-message">
                            {errors.licenseExpiry}
                          </span>
                        )}
                      </label>

                      {/* Address */}
                      <label className="col-md-12 mb-3">
                        <span>
                          Street Address{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>

                        {/* Street Address */}
                        <input
                          type="text"
                          name="address"
                          placeholder="Street Address"
                          value={formData.address}
                          maxLength={100}
                          onChange={(e) => {
                            handleChange(e);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              address: "",
                            }));
                          }}
                          required
                          style={{
                            display: "block",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                        />
                        {errors.address && (
                          <span className="error-message">
                            {errors.address}
                          </span>
                        )}

                        {/* City */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                          }}
                        >
                          <div className="col">
                            <input
                              type="text"
                              name="city"
                              placeholder="City"
                              value={formData.city}
                              maxLength={50}
                              onChange={(e) => {
                                handleChange(e);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  city: "",
                                }));
                              }}
                              required
                              style={{
                                display: "block",
                                width: "100%",
                                marginBottom: "10px",
                              }}
                            />
                            {errors.city && (
                              <span className="error-message">
                                {errors.city}
                              </span>
                            )}
                          </div>

                          {/* State */}
                          <div className="col">
                            <select
                              name="drivingState"
                              value={formData.drivingState || ""}
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.drivingState) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    drivingState: "",
                                  }));
                                }
                              }}
                              required
                              style={{
                                backgroundColor: "#fff",
                                height: "50px",
                              }}
                            >
                              <option value="">Select a state</option>
                              {usStates.map((state) => (
                                <option key={state.name} value={state.name}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.drivingState && (
                              <span style={{ color: "red" }}>
                                {errors.drivingState}
                              </span>
                            )}
                          </div>

                          {/* Zip Code */}
                          <div className="col">
                            <input
                              type="text"
                              name="zipcode"
                              placeholder="Zip Code"
                              value={formData.zipcode}
                              maxLength={5}
                              onChange={(e) => {
                                handleChange(e);
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  zipcode: "",
                                }));
                              }}
                              required
                              style={{ display: "block", width: "100%" }}
                            />
                            {errors.zipcode && (
                              <span className="error-message">
                                {errors.zipcode}
                              </span>
                            )}
                          </div>
                        </div>
                      </label>
                    </div>

                    {/* Submit Button */}
                    <div
                      className="submit-form-btn"
                      onClick={() => {
                        handleNext(); // Proceed to the next step
                      }}
                    >
                      Next
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div className="form-step ">
                    <h6 className="mb-4">Step 2: Rental Details</h6>
                    <div className="row m-0">
                      <label className="col-md-6 mb-3">
                        Last 4 of SSN <span style={{ color: "red" }}>*</span>
                        <input
                          type="text"
                          name="ssn"
                          value={formData.ssn}
                          maxLength={4}
                          onChange={(e) => {
                            handleChange(e);
                            if (errors.ssn) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ssn: "",
                              }));
                            }
                          }}
                          required
                        />
                        {errors.ssn && (
                          <span style={{ color: "red" }}>{errors.ssn}</span>
                        )}
                      </label>
                      <label className="col-md-6 mb-3">
                        Select State <span style={{ color: "red" }}>*</span>
                        <select
                          name="licenseState"
                          value={formData.licenseState || ""}
                          onChange={(e) => {
                            handleChange(e);
                            if (errors.licenseState) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                licenseState: "",
                              }));
                            }
                          }}
                          required
                          style={{ backgroundColor: "#fff", height: "50px" }}
                        >
                          <option value="">Select a state</option>
                          {usStates.map((state) => (
                            <option key={state.name} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {errors.licenseState && (
                          <span style={{ color: "red" }}>
                            {errors.licenseState}
                          </span>
                        )}
                      </label>
                      <div className="col-md-6 mb-3">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "8px",
                          }}
                        >
                          <label>
                            Upload Drivers License{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {licenseImage ? (
                            isPdfFile ? (
                              // For PDF files, provide a link to open in new tab
                              <a
                                href={licenseImage}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary"
                                style={{ padding: "2px 8px" }}
                              >
                                <Eye size={18} /> View PDF
                              </a>
                            ) : // For images, don't show any button
                              null
                          ) : (
                            <p style={{ color: "red" }}>No license uploaded</p>
                          )}
                        </div>

                        {/* Image Preview Area - always shown for images */}
                        {!isPdfFile && licenseImage && (
                          <div
                            style={{
                              marginTop: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              padding: "10px",
                              // height: "200px",
                              // overflow: "auto",
                              // position:'absolute'
                            }}
                          >
                            <img
                              src={licenseImage}
                              alt="Driver's License"
                              style={{ width: "250px" }}
                              onLoad={() =>
                                console.log("Image loaded successfully")
                              }
                              onError={(e) => {
                                console.error("Error loading image:", e);
                                e.target.style.display = "none";
                                document.getElementById(
                                  "image-error"
                                ).style.display = "block";
                              }}
                            />
                            <div
                              id="image-error"
                              style={{
                                display: "none",
                                color: "red",
                                textAlign: "center",
                              }}
                            >
                              Unable to load image. <br />
                              <a
                                href={licenseImage}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-sm btn-outline-primary mt-2"
                              >
                                Open in New Tab
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <label className="col-md-6 mb-3">
                        Driver's License number{" "}
                        <span style={{ color: "red" }}>*</span>
                        <input
                          type="text"
                          name="license"
                          value={formData.license}
                          maxLength={14}
                          onChange={(e) => {
                            handleAlphabetsNumberWithoutSpaceChange(e);
                            handleChange(e);
                            if (errors.license) {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                license: "",
                              }));
                            }
                          }}
                          required
                        />
                        {errors.license && (
                          <span style={{ color: "red" }}>{errors.license}</span>
                        )}
                      </label>
                      {/* <label
                        className="col-md-6 mb-3 "
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>
                          Upload Drivers License{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>

                      
                        {licenseImage ? (
                          <button
                            onClick={handleViewClick}
                            className="btn btn-primary"
                            style={{ padding: "2px 8px" }}
                          >
                            <Eye size={18} /> {showPreview ? "Hide" : "View"}{" "}
                            License
                          </button>
                        ) : (
                          <p style={{ color: "red" }}>No license uploaded</p>
                        )}
                      </label>
                   
                      {showPreview && (
                        <div
                          style={{
                            marginTop: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            padding: "10px",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          {isLoading && (
                            <div
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              <p>Loading preview...</p>
                            </div>
                          )}

                          {loadError && (
                            <div style={{ textAlign: "center", color: "red" }}>
                              <p>Unable to load the file for preview.</p>
                              <a
                                href={licenseImage}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-sm btn-outline-primary"
                              >
                                Open In New Tab
                              </a>
                            </div>
                          )}

                          {!isLoading && !loadError && fileData && (
                            <div style={{ textAlign: "center" }}>
                              {fileType === "pdf" ? (
                                <object
                                  data={fileData}
                                  type="application/pdf"
                                  width="100%"
                                  height="300"
                                >
                                  <p>
                                    Your browser doesn't support PDF preview.
                                    <a
                                      href={fileData}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Download the PDF
                                    </a>
                                  </p>
                                </object>
                              ) : (
                                <img
                                  src={fileData}
                                  alt="Driver's License"
                                  style={{ maxWidth: "100%" }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )} */}

                      {/* <label className="col-md-6 mb-3">
                        <span>
                          Are you approved by any rideshare / delivery platform
                          <span style={{ color: "red" }}>*</span>
                        </span>

                       
                        <div
                          className="check_box_mvr"
                          style={{ marginTop: "10px" }}
                        >
                          <input
                            type="radio"
                            id="rideshareApproval"
                            name="rideshareApproval"
                            value="yes"
                            checked={formData.rideshareApproval === "yes"}
                            onChange={(e) => {
                              handleChange(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                rideshareApproval: "",
                              }));
                            }}
                          />
                          <label
                            htmlFor="rideshareApproval"
                            style={{ marginRight: "20px" }}
                          >
                            Yes
                          </label>

                          <input
                            type="radio"
                            id="rideshareApproval"
                            name="rideshareApproval"
                            value="no"
                            checked={formData.rideshareApproval === "no"}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.rideshareApproval) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  rideshareApproval: "",
                                }));
                              }
                            }}
                          />
                          <label htmlFor="mvrNo">No</label>
                        </div>
                        {errors.rideshareApproval && (
                          <span style={{ color: "red" }}>
                            {errors.rideshareApproval}
                          </span>
                        )}
                      </label> */}

                      {/* {formData.rideshareApproval === "yes" && (
                        <label className="col-md-6 mb-3">
                          How many years of passenger-for-hire experience?{" "}
                          <span style={{ color: "red" }}>*</span>
                          <input
                            type="text"
                            name="hireExperience"
                            value={formData.hireExperience}
                            maxLength={2}
                            onInput={handleNumbersChange}
                            onChange={(e) => {
                              handleChange(e);
                              if (errors.hireExperience) {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  hireExperience: "",
                                }));
                              }
                            }}
                            required
                          />
                          {errors.hireExperience && (
                            <span style={{ color: "red" }}>
                              {errors.hireExperience}
                            </span>
                          )}
                        </label>
                      )} */}
                      {rideshare === "no" ? (
                        <label className="col-md-12 mb-3">
                          <span className="subLabel">
                            Using car for (Select all that apply){" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Rideshare"
                                checked={formData.carUsage.includes(
                                  "Rideshare"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Rideshare
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Commute to work"
                                checked={formData.carUsage.includes(
                                  "Commute to work"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Commute to work
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="carUsage"
                                value="Other"
                                checked={formData.carUsage.includes("Other")}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.carUsage) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      carUsage: "",
                                    }));
                                  }
                                }}
                              />
                              Other
                            </label>
                            {formData.carUsage.includes("Other") && (
                              <input
                                type="text"
                                name="carUsageOther"
                                placeholder="Specify other usage"
                                value={formData.carUsageOther}
                                onChange={handleChange}
                              />
                            )}
                          </div>
                          {errors.carUsage && (
                            <span style={{ color: "red" }}>
                              {errors.carUsage}
                            </span>
                          )}
                        </label>
                      ) : (
                        <label className="col-md-12 mb-3">
                          <span className="subLabel">
                            Approved and ready to work for (Select all that
                            apply)
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <div>
                            <label>
                              <input
                                type="checkbox"
                                name="workApproval"
                                value="Uber"
                                checked={formData.workApproval.includes("Uber")}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.workApproval) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      workApproval: "",
                                    }));
                                  }
                                }}
                              />
                              Uber
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="workApproval"
                                value="GIG Work"
                                checked={formData.workApproval.includes(
                                  "GIG Work"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.workApproval) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      workApproval: "",
                                    }));
                                  }
                                }}
                              />
                              GIG Work
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="workApproval"
                                value="Lyft"
                                checked={formData.workApproval.includes("Lyft")}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.workApproval) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      workApproval: "",
                                    }));
                                  }
                                }}
                              />
                              Lyft
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="workApproval"
                                value="Other"
                                checked={formData.workApproval.includes(
                                  "Other"
                                )}
                                onChange={(e) => {
                                  handleChange(e);
                                  if (errors.workApproval) {
                                    setErrors((prevErrors) => ({
                                      ...prevErrors,
                                      workApproval: "",
                                    }));
                                  }
                                }}
                              />
                              Other
                            </label>
                            {formData.workApproval.includes("Other") && (
                              <input
                                type="text"
                                name="workApprovalOther"
                                placeholder="Specify other platform"
                                value={formData.workApprovalOther}
                                onChange={handleChange}
                              />
                            )}
                          </div>
                          {errors.workApproval && (
                            <span style={{ color: "red" }}>
                              {errors.workApproval}
                            </span>
                          )}
                        </label>
                      )}

                      {/* <label className="col-md-12 mb-3">
                        <span className="subLabel">
                          When do you need the car?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <div className="radio-group">
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 1-2 days"
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 1-2 days
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 2-3 days"
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 2-3 days
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="rentalStart"
                              value="Within 4-7 days"
                              onChange={(e) => {
                                handleChange(e);
                                if (errors.rentalStart) {
                                  setErrors((prevErrors) => ({
                                    ...prevErrors,
                                    rentalStart: "",
                                  }));
                                }
                              }}
                            />
                            Within 4-7 days
                          </label>
                        </div>
                        {errors.rentalStart && (
                          <span style={{ color: "red" }}>
                            {errors.rentalStart}
                          </span>
                        )}
                      </label> */}
                      <h6>Emergency Contact 1</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "15px",
                        }}
                        className="col-md-12 mb-3 mt-2"
                      >
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Name
                            <input
                              type="text"
                              name="name"
                              value={formData.emergencyContact1.name}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                            />
                            {errors.emergencyContact1_name && (
                              <span className="error">
                                {errors.emergencyContact1_name}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Relation
                            <input
                              type="text"
                              name="relation"
                              value={formData.emergencyContact1.relation}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                            />
                            {errors.emergencyContact1_relation && (
                              <span className="error">
                                {errors.emergencyContact1_relation}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              value={formData.emergencyContact1.phone}
                              onChange={(e) => handleEmergencyChange(e, 1)}
                              maxLength={10}
                            />
                            {errors.emergencyContact1_phone && (
                              <span className="error">
                                {errors.emergencyContact1_phone}
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      <h6>Emergency Contact 2</h6>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "15px",
                        }}
                        className="col-md-12 mb-3 mt-2"
                      >
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Name
                            <input
                              type="text"
                              name="name"
                              value={formData.emergencyContact2.name}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                            />
                            {errors.emergencyContact2_name && (
                              <span className="error">
                                {errors.emergencyContact2_name}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Relation
                            <input
                              type="text"
                              name="relation"
                              value={formData.emergencyContact2.relation}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                            />
                            {errors.emergencyContact2_relation && (
                              <span className="error">
                                {errors.emergencyContact2_relation}
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="col-md-3">
                          {" "}
                          <label className="label768">
                            Phone
                            <input
                              type="tel"
                              name="phone"
                              value={formData.emergencyContact2.phone}
                              onChange={(e) => handleEmergencyChange(e, 2)}
                              maxLength={10}
                            />
                            {errors.emergencyContact2_phone && (
                              <span className="error">
                                {errors.emergencyContact2_phone}
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      {error && <p style={{ color: "red" }}>{error}</p>}
                      <h6>Signature</h6>
                      <div className="signature-section mt-4 col-md-12 mb-3">
                        {/* <h5>Signature</h5> */}
                        <SignatureCanvas
                          ref={signaturePad}
                          penColor="black"
                          canvasProps={{
                            height: 200,
                            width: 300,
                            className: "signature-canvas border rounded",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            // type="button"
                            className="submit-form-btn-small"
                            onClick={handleClearSignature}
                          >
                            Clear
                          </div>
                          <div
                            // type="button"
                            className="submit-form-btn-small"
                            onClick={handleSaveSignature}
                          >
                            Save Signature
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Checkbox for agreement */}
                    {/* <div className="box-remember-forgot">
                      <div className="form-group">
                        <div className="remeber-me">
                          <label className="text-sm-medium neutral-500">
                            <input
                              name="agreed"
                              className="cb-remember"
                              type="checkbox"
                              checked={agreed}
                              onChange={handleCheckboxChange}
                            />{" "}
                            Agree to our{" "}
                            <a
                              className="text-sm-medium neutral-1000"
                              href="/terms_of_use"
                            >
                              Terms of service
                            </a>{" "}
                            and{" "}
                            <a
                              className="text-sm-medium neutral-1000"
                              href="/privacy_policy"
                            >
                              Privacy Policy
                            </a>{" "}
                            for Agreement to Rent Vehicle
                          </label>
                        </div>
                      </div>
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="submit-form-btn" onClick={handleBack}>
                        Back
                      </div>
                      {/* <div
                        className={`submit-form-btn ${
                          !agreed ? "disabled" : ""
                        }`}
                        onClick={agreed ? handleSubmit : undefined}
                        style={{
                          cursor: agreed ? "pointer" : "not-allowed",
                        }}
                      >
                        Submit
                      </div> */}
                      <div className="submit-form-btn" onClick={handleSubmit}>
                        Submit
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      {/**----------t & C modal---------------- */}
      <Modal
        show={termConditionModal}
        onHide={() => setTermConditionModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="modl_title_tc_head">
              {" "}
              Terms and Conditions for Agreement to Rent Vehicle
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="terms_Contrrr" className="terms_Contrrr" ref={termsRef}>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>1.</span> The vehicle may be
              driven during the rental term only by the persons named in this
              Document.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>2.</span> The renter shall
              pay the rent rental of the vehicle the sum or sums specified in
              this Document; and authorizes the owner to charge all amounts
              payable to the renter's account. The renter's account means a
              nominated debit card, credit card, or pre-arranged charge account.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>3.</span> In addition to the
              payment specified in clause 2 above, the renter acknowledges that
              they shall be liable for the rental term any applicable additional
              charges payable at the end of the term. These include, but are not
              limited to:
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(a.)</span> Charges for gas
              or other fuel used; Road user charges / Toll Charges; Charges for
              late return of the vehicle etc;
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(b.)</span> Charges for
              cleaning the vehicle's interior, if the vehicle is returned in a
              dirty condition that requires extra cleaning or deodorizing. This
              includes, but is not limited to, spillage of fluids, food, vomit,
              other stains, and unpleasant odors including cigarette smoke.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(c.)</span> Liability of
              Traffic and/or parking Tickets / offence infringement fees.
            </p>

            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "10px",
                marginTop: "15px",
                marginBottom: "15px",
                borderRadius: "5px",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Agree Point 1 to 3(c)? *
              </p>
              <p style={{ marginLeft: "10px" }}>Yes, Agree Point 1 to 3(c)</p>
            </div>

            <h2
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "center",
                color: "#333",
                lineHeight: "20px",
              }}
            >
              Terms and Conditions for Agreement to Rent Vehicle
            </h2>

            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>4.</span> The renter shall
              NOT:
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(a.)</span> Sublet or rental
              the vehicle to any other person.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(b.)</span> Allow the vehicle
              to be used outside his/her authority.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(c.)</span> Operate the
              vehicle or allow it to be operated in circumstances that
              constitute an offence against any of Sections 56, 57 or 58 of the
              Act (which relates to driving under the influence of alcohol or
              drugs).
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(d.)</span> Operate the
              vehicle or allow it to be operated in any race, speed test,
              reliability trial, rally, or contest, or operated on any race or
              rally circuit or in any event as a pacemaker or testing in
              preparation for any of them.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(e.)</span> Operate the
              vehicle or allow it to be operated, in breach of the Act, the Land
              Transport (Road User) Rule 2004, or any other Act, regulations,
              rules or bylaws relating to road traffic.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(f.)</span> Operate the
              vehicle or allow it to be operated for the transport of more
              passengers or goods than the maximum specified in the certificate
              of loading and/or road user charge certificate, whichever is the
              lesser, for the vehicle.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(g.)</span> Drive or allow
              the vehicle to be driven on any roads excluded in clause 22(q) of
              these terms and conditions, or on any beach, driveway, or surface
              likely to damage the vehicle.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(h.)</span> Allow the vehicle
              to be driven by any person who is not named or described in the
              Rental Document as a person permitted to drive the vehicle.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(i.)</span> Operate the
              vehicle or allow it to be operated to propel or tow any other
              vehicle.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(j.)</span> Operate or allow
              the vehicle to be used in involvement with any illegal activity.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(k.)</span> Do Smoking or do
              any illegal drugs in the vehicle.
            </p>
            <p style={{ marginBottom: "15px" }}>
              <span style={{ fontWeight: "bold" }}>5. RETURN OF VEHICLE:</span>{" "}
              The renter shall, at or before the expiry of the term of rental,
              deliver the vehicle to the agreed rental location described in the
              Rental Document or obtain the owner's consent to the continuation
              of the rental (in which case the renter shall pay additional
              rental charges for the extended term of rental). If the renter
              does not comply with this clause, and does not immediately return
              the vehicle, the owner may report the vehicle as stolen to the
              Police and the renter must compensate the owner for either the
              full cost of the vehicle, or all additional costs and losses
              incurred up to the time that the vehicle is recovered by the
              owner.
            </p>

            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "10px",
                marginTop: "15px",
                marginBottom: "15px",
                borderRadius: "5px",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                Agree Point 4 (a) to (k) & 5? *
              </p>
              <p style={{ marginLeft: "10px" }}>
                Yes, Agree Point 4 (a) to (k) & 5
              </p>
            </div>

            <h2
              style={{
                fontWeight: "bold",
                fontSize: "15px",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "start",
                color: "black",
                lineHeight: "20px",
              }}
            >
              Terms and Conditions for Agreement to Rent Vehicle
            </h2>

            <p
              style={{
                marginBottom: "15px",
                fontWeight: "bold",
                fontSize: "13px",
                color: "#333",
                backgroundColor: "#f5f5f5",
                padding: "10px",
                borderRadius: "5px",
                textAlign: "start",
              }}
            >
              6. READ AND ACCEPT FOLLOWING CONDITIONS OF RENTAL AGREEMENT: *
            </p>
            <ul className="point_agry_terms">
              <li>
                OWNER requires that all vehicles to be brought bi-weekly for
                vehicle servicing.<span>.</span>
              </li>
              <li>
                Any issues with the vehicle must be immediately reported.
                <span>.</span>
              </li>
              <li>
                The owner has the right to terminate the rental and take
                immediate possession of the vehicle, if the renter fails to
                comply with any of the terms of the Rental Agreement or if the
                vehicle is damaged.<span>.</span>
              </li>
              <li>
                Key damage / loss charge is $250<span>.</span>
              </li>
              <li>
                Repossession Charge is $250<span>.</span>
              </li>
              <li>
                Late fee is $10 Per day<span>.</span>
              </li>
              <li>
                Car will be picked up in case of non-payment for extending lease
                period.<span>.</span>
              </li>
              <li>
                If there is any payment is balance, recovery action will be
                taken.<span>.</span>
              </li>
              <li>
                Deposit if any will be forfeited and will be treated as car
                recovery fees.<span>.</span>
              </li>
              <li>
                I understand that any payment made is not refundable.
                <span>.</span>
              </li>
            </ul>

            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                7. Damage to the vehicle:
              </span>{" "}
              You will be personally responsible for any damage to the car.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>8.</span> DO NOT Leave car
              keys in the car and turnoff the car when you leave the car.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>9.</span> If car is Stolen /
              lost due to negligence, Renter will be responsible to pay the cost
              of car to the owner.
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>10.</span> In any event, the
              car will be released only to owner's authorize person / towing
              Company
            </p>
            <p style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>11. Deductibles:</span> $1000
              per incident will be driver's responsibility. Rideshare platforms
              deductibles may be $2500 per incident.
            </p>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              Owner Responsibility: Vehicle Insurance & Maintenance.
            </p>
            <p
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
                color: "#333",
              }}
            >
              OWNER, owner's Management, and its employees are NOT responsible
              for:
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(a.)</span> Any fault of the
              vehicle, any accidents, incident, unknown issues, natural cause,
              including any injury / death.
            </p>
            <p style={{ marginBottom: "10px", paddingLeft: "20px" }}>
              <span style={{ fontWeight: "bold" }}>(b.)</span> Anything not
              covered by insurance, things left in the car after you drop-off or
              we have repossessed the car. Any driving/ speeding/ parking
              tickets.
            </p>

            {/* <div style={{ marginTop: "20px", marginBottom: "10px" }}>
              <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
                Today's Date *
              </p>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
              >
                <input
                  type="text"
                  placeholder="Month"
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "80px",
                  }}
                />
                <input
                  type="text"
                  placeholder="Day"
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "80px",
                  }}
                />
                <input
                  type="text"
                  placeholder="Year"
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "80px",
                  }}
                />
              </div>
            </div> */}
          </div>

          <div
            className="check_box_dvv"
            style={{ opacity: isCheckboxEnabled ? 1 : 0.5 }}
          >
            <input
              name="agreed2"
              className="cb-remember"
              type="checkbox"
              checked={agreed2}
              disabled={!isCheckboxEnabled}
              onChange={(e) => setAgreed2(e.target.checked)}
            />{" "}
            <label>I have read and agree to the terms and conditions. </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="fottr_bttns">
            <Button
              variant="secondary"
              className="nobttn"
              onClick={() => setTermConditionModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="yesBttn"
              onClick={confirmSubmit}
              disabled={!agreed2}
              style={{ opacity: agreed2 ? 1 : 0.5 }}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default Agreement;
